import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import axios from "axios";
import cookie from "js-cookie";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";

const PartnersShow = ({ match }) => {
	const {
		params: { partnerID },
	} = match;

	const history = useHistory();
	const auth = useSelector(state => state.auth.user);
	const [picture, setPicture] = useState();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل الشريك",
				breadcrumb: "الشركاء",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/pages/Partners/sections/${partnerID}`,
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				// console.log(response);
				setPicture({ ...response.data.data.section });
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handlePicture = () => {
		setLoading(true);
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setPicture({
					...picture,
					image: { blob: false, path: response.data.data.file.path },
				});
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const handleSubmit = e => {
		e.preventDefault();
		axios
			.put(
				`${process.env.REACT_APP_API_URL}pages/Partners/sections/${partnerID}`,
				{
					section: {
						...picture,
					},
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/partners/index") &&
					toast.success("تم تحديث الشريك بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-3 mx-auto">
						<div className="form-item">
							<input type="file" onChange={handlePicture} id="picture" />
							<img
								src={
									picture?.image?.blob && picture?.image?.blob
										? picture?.image?.path
										: `${process.env.REACT_APP_MAIN_URL}${picture?.image?.path}` ??
										  userPlaceholder
								}
								alt="avatar"
								className="imagePreview"
							/>
						</div>
						<div className="form-item">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default PartnersShow;

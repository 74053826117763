import { Link } from "react-router-dom";

const Breadcrumb = props => {
	return (
		<ul className="breadcrumbs list-inline">
			<li className="list-inline-item">
				<Link to="/">لوحة التحكم</Link>
			</li>
			<li className="list-inline-item">
				<i className="fas fa-chevron-left"></i>
			</li>
			<li className="list-inline-item">{props.breadcrumbTitle}</li>
		</ul>
	);
};

export default Breadcrumb;

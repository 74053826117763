import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";
import { Container, Col, Row } from "reactstrap";
const WorksIndex = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "جميع المنتجات",
				breadcrumb: "المنتجات",
				button: {
					show: true,
					text: "اضافة منتج جديد",
					link: "/works/create",
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}pages/our-works`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setData(response.data.data.page.sections);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
				setLoading(false);
			});
	};

	const handleDelete = workID => {
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}pages/our-works/sections/${workID}`,
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				onStart();
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<Container>
				<Row>
					{data.map(post => {
						return (
							<div key={post.slug} className="col-md-3 col-6">
								<div
									className="serviceItem"
									style={{
										padding: "unset",
										padding: "1rem",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<button onClick={() => handleDelete(post.slug)}>
										<i className="fas fa-trash" />
									</button>
									<Link to={`/works/show/${post.slug}`}>
										<img
											src={`${process.env.REACT_APP_MAIN_URL}${post.image.path}`}
											alt={post.titleObj.ar}
										/>
										<h1>{post.titleObj.ar}</h1>
									</Link>
								</div>
							</div>
						);
					})}
				</Row>
			</Container>
		</section>
	);
};

// TODO finalize the Work Seaction and add EDit Section for every sections

export default WorksIndex;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";
import userPlaceholder from "../../../assets/images/placeholder.jpg";
import { updateMeta } from "../../../store/actions/metaAction";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

const ProductThreePostsCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");

	const [titleEn, setTitleEn] = useState("");
	const [descriptionEn, setDescriptionEn] = useState("");
	const [loading, setLoading] = useState(false);
	const [picture, setPicture] = useState(userPlaceholder);
	const [video, setVideo] = useState(userPlaceholder);
	const dispatch = useDispatch();

	let editor = null;

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة بوست جديد",
				breadcrumb: "بوست",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	const handlePicture = () => {
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
	};

	const handleVideo = () => {
		const fileInput = document.querySelector("#video");
		setVideo(URL.createObjectURL(fileInput.files[0]));
	};

	const handleSubmit = e => {
		e.preventDefault();

		if (!title || !titleEn || !description || !descriptionEn) {
			return toast.error("جميع البيانات مطلوبة");
		}
		const picture = document.querySelector("#picture");
		if (picture.files[0]) {
			setLoading(true);
			const data = new FormData();
			data.append("file", picture.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					const video = document.querySelector("#video");
					if (video.files[0]) {
						setLoading(true);
						const data = new FormData();
						data.append("file", video.files[0]);
						axios
							.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
								headers: {
									Authorization: `Bearer ${cookie.get("token")}`,
									Accept: "application/json",
									"Content-Type": "multipart/form-data",
								},
							})
							.then(responseVideo => {
								const data = {
									title: {
										ar: title,
										en: titleEn,
									},
									description: {
										ar: description,
										en: descriptionEn,
									},
									image: response.data.data.name,
									video: responseVideo.data.data.name,
								};
								axios
									.post(
										`${process.env.REACT_APP_API_URL}/posts`,
										{
											admin_id: auth.id,
											category_id: 41,
											data: data,
										},
										{
											headers: {
												Authorization: `Bearer ${cookie.get("token")}`,
												Accept: "application/json",
											},
										}
									)
									.then(function (response) {
										history.push("/productThreePage/ProductThreePosts/index");
										toast.success("تم اضافة البوست بنجاح!");
										setLoading(false);
									})
									.catch(function (error) {
										toast.error("خطأ، برجاء المحاولة مره اخرى!");
										setLoading(false);
									});
							});
					} else {
						const data = {
							title: {
								ar: title,
								en: titleEn,
							},
							description: {
								ar: description,
								en: descriptionEn,
							},
							image: response.data.data.name,
							// video: responseVideo.data.data.name,
						};
						axios
							.post(
								`${process.env.REACT_APP_API_URL}/posts`,
								{
									admin_id: auth.id,
									category_id: 41,
									data: data,
								},
								{
									headers: {
										Authorization: `Bearer ${cookie.get("token")}`,
										Accept: "application/json",
									},
								}
							)
							.then(function (response) {
								history.push("/productThreePage/ProductThreePosts/index");
								toast.success("تم اضافة البوست بنجاح!");
								setLoading(false);
							})
							.catch(function (error) {
								toast.error("خطأ، برجاء المحاولة مره اخرى!");
								setLoading(false);
							});
					}
				})
				.catch(error => {
					console.log(error);
					toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
					setLoading(false);
				});
		} else {
			toast.error("برجاء اختيار الصورة أولا!");
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-8">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="title">العنوان</label>
									<input
										type="text"
										id="title"
										onChange={e => setTitle(e.target.value)}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="description">الوصف</label>

									<CKEditor
										data={description}
										onReady={editor => {
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement()
												);
											editor = editor;
										}}
										onError={({ willEditorRestart }) => {
											if (willEditorRestart) {
												editor.ui.view.toolbar.element.remove();
											}
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											setDescription(data);
										}}
										editor={DecoupledEditor}
										config={{
											contentsLangDirection: "rtl",
											language: "ar",
										}}
									/>
								</div>
								<div className="form-item">
									<label htmlFor="title"> العنوان بالانجليزيه </label>
									<input
										type="text"
										id="title"
										onChange={e => setTitleEn(e.target.value)}
									/>
								</div>

								<div className="form-item">
									<label htmlFor="description"> الوصف بالانجليزيه</label>

									<CKEditor
										data={descriptionEn}
										onReady={editor => {
											editor.ui
												.getEditableElement()
												.parentElement.insertBefore(
													editor.ui.view.toolbar.element,
													editor.ui.getEditableElement()
												);
											editor = editor;
										}}
										onError={({ willEditorRestart }) => {
											if (willEditorRestart) {
												editor.ui.view.toolbar.element.remove();
											}
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											setDescriptionEn(data);
										}}
										editor={DecoupledEditor}
										config={{
											contentsLangDirection: "ltr",
											language: "en",
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="picture">الصوره</label>

									<input type="file" onChange={handlePicture} id="picture" />
									<img src={picture} alt="avatar" className="imagePreview" />
								</div>
								<div className="form-item">
									<label htmlFor="video">الفيديو</label>
									<input type="file" onChange={handleVideo} id="video" />
								</div>
							</div>
						</div>
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default ProductThreePostsCreate;

import React, { useState, useEffect } from "react";
import axios from "axios";
import cookie, { set } from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { Link, Redirect } from "react-router-dom";
import Loader from "../../../components/shared/Loader";
import userPlaceholder from "../../../assets/images/placeholder.jpg";
import { updateMeta } from "../../../store/actions/metaAction";
import { v4 } from "uuid";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Col, Container, Row } from "reactstrap";

const SideInfoShow = ({ match }) => {
	const {
		params: { sideinfoID },
	} = match;
	const [sideInfo, setSideInfo] = useState([]);
	const [submited, setSubmited] = useState(false);
	const [loading, setLoading] = useState(true);
	const [placeHolderData, setPlaceHolderData] = useState({});
	const auth = useSelector(state => state.auth.user);
	const [picture, setPicture] = useState();
	const [currentInfo, setCurrentInfo] = useState({});
	const [DataLoaded, setDataLoaded] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const handlePicture = e => {
		setLoading(true);
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
		const data = new FormData();
		data.append("file", fileInput.files[0]);
		axios
			.post(`${process.env.REACT_APP_MAIN_URL}/api/admincp/upload`, data, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(response => {
				setPicture(response.data.data.name);
				setLoading(false);
				toast.success("تم رفع الصورة بنجاح!");
			})
			.catch(error => {
				console.log(error);
				toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
				setLoading(false);
			});
	};

	const updataComments = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/207`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				let target = {
					...response.data.data.data.taps.filter(
						info => info.id === sideinfoID
					)[0],
				};
				setPlaceHolderData({ ...response.data.data.data });
				setSideInfo([...response.data.data.data.taps]);
				setCurrentInfo({ ...target });
				setPicture(target.image ?? "");
				setLoading(false);
				setDataLoaded(true);
			})
			.catch(function (error) {
				console.log(error);
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	let editor = null;

	const onStart = () => {
		dispatch(
			updateMeta({
				title: " اضافة معلومة اضافية",
				breadcrumb: "المعلومات عن الموقع",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		updataComments();
	};

	const handleSubmit = e => {
		e.preventDefault();
		const taps = [
			...sideInfo.map(info =>
				info.id == sideinfoID
					? {
							...info,
							description: { ...currentInfo.description },
							title: { ...currentInfo.title },
							image: picture,
					  }
					: { ...info }
			),
		];
		const data = {
			...placeHolderData,
			taps: taps,
		};
		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/207`,
				{
					admin_id: auth.id,
					category_id: 1,
					data: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				toast.success("تم تحديث الخدمة بنجاح!");
				setLoading(false);
				setSubmited(true)
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form method="post" onSubmit={handleSubmit}>
				<Container>
					<Row>
						<Col
							xs={4}
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
								padding: "1rem",
							}}
						>
							<label htmlFor="Image"> الصورة</label>
							<input type="file" onChange={handlePicture} id="picture" />
							<img
								src={process.env.REACT_APP_MAIN_URL + picture}
								alt="avatar"
								className="imagePreview"
							/>
						</Col>
					</Row>
					<Row>
						<Col
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
								padding: "1rem",
							}}
						>
							<label htmlFor="TitelAR"> العنوان</label>
							<input
								type="text"
								id="TitelAR"
								style={{ all: "revert" }}
								value={currentInfo?.title?.ar ?? ""}
								placeholder="ادخل العنوان هنا"
								onChange={e =>
									setCurrentInfo({
										...currentInfo,
										title: { ...currentInfo.title, ar: e.target.value },
									})
								}
							/>
						</Col>
					</Row>
					<Row>
						<Col
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
								padding: "1rem",
							}}
						>
							<label htmlFor="TitelEN"> العنوان بالانجليزية</label>
							<input
								type="text"
								id="TitelEN"
								placeholder="ادخل العنوان هنا"
								style={{ all: "revert" }}
								value={currentInfo?.title?.en ?? ""}
								onChange={e =>
									setCurrentInfo({
										...currentInfo,
										title: { ...currentInfo.title, en: e.target.value },
									})
								}
							/>
						</Col>
					</Row>
					<Row>
						<Col
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
								padding: "1rem",
							}}
						>
							<label htmlFor="DescriptionAR">الوصف</label>
							<CKEditor
								onReady={editor => {
									editor.ui
										.getEditableElement()
										.parentElement.insertBefore(
											editor.ui.view.toolbar.element,
											editor.ui.getEditableElement()
										);
									editor = editor;
								}}
								onError={({ willEditorRestart }) => {
									if (willEditorRestart) {
										editor.ui.view.toolbar.element.remove();
									}
								}}
								onChange={(event, editor) => {
									const data = editor.getData();
									DataLoaded &&
										setCurrentInfo({
											...currentInfo,
											description: {
												...currentInfo.description,
												ar: data,
											},
										});
								}}
								editor={DecoupledEditor}
								config={{
									contentsLangDirection: "rtl",
									language: "ar",
								}}
								data={
									currentInfo?.description?.ar ? currentInfo.description.ar : ""
								}
							/>
						</Col>
					</Row>
					<Row>
						<Col
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
								padding: "1rem",
							}}
						>
							<label htmlFor="DescriptionEn">الوصف بالانجليزية</label>
							<CKEditor
								onReady={editor => {
									editor.ui
										.getEditableElement()
										.parentElement.insertBefore(
											editor.ui.view.toolbar.element,
											editor.ui.getEditableElement()
										);
									editor = editor;
								}}
								onError={({ willEditorRestart }) => {
									if (willEditorRestart) {
										editor.ui.view.toolbar.element.remove();
									}
								}}
								onChange={(event, editor) => {
									const data = editor.getData();
									DataLoaded &&
										setCurrentInfo({
											...currentInfo,
											description: {
												...currentInfo.description,
												en: data,
											},
										});
								}}
								editor={DecoupledEditor}
								config={{
									contentsLangDirection: "ltr",
									language: "en",
								}}
								data={currentInfo?.description?.en ?? ""}
							/>
						</Col>
					</Row>
				</Container>

				<div className="form-item mt-3">
					<button className="bt bt-success btn-block">ارسال</button>
				</div>
				{submited ? (
					<Redirect
						to={{
							pathname: "/aboutPage/sideinfo/index",
						}}
					/>
				) : (
					""
				)}
			</form>
		</section>
	);
};

export default SideInfoShow;

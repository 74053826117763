import { Link } from "react-router-dom";

const NavItem = props => {
	const toggleResponsiveMenu = () => {
		const sidebar = document.getElementById("sidebar");
		sidebar.classList.toggle("sidebar-responsive");
		const sidebarWrapper = document.getElementById("sm-wrapper");
		sidebarWrapper.classList.toggle("sm-wrapper-toggle");
	};

	return props.item.children.length > 0 ? (
		<div className="side-item">
			<div id={`heading-${props.item.id}`}>
				<button
					type="button"
					data-toggle="collapse"
					data-target={`#navItem-${props.item.id}`}
					aria-expanded="true"
					aria-controls="collapseOne"
				>
					<div className="link">
						<i className={`${props.item.icon} change-color`}></i>
						<span>{props.item.title}</span>
					</div>
					<i className="fas fa-chevron-down arrow-left"></i>
					<div className="hover"></div>
				</button>
			</div>
			<div
				id={`navItem-${props.item.id}`}
				className="collapse"
				aria-labelledby="headingOne"
				data-parent="#sidebar-menu"
			>
				<ul className="list-unstyled hide-on-collapse">
					{props.item.children.map(child => (
						<li key={child.id} onClick={toggleResponsiveMenu}>
							<Link to={child.link}>{child.title}</Link>
						</li>
					))}
				</ul>
			</div>
		</div>
	) : (
		<Link
			to={props.item.link}
			className="no-child"
			onClick={toggleResponsiveMenu}
		>
			<div className="link">
				<i className={`${props.item.icon} change-color`}></i>
				<span>{props.item.title}</span>
			</div>
			<i className="fas fa-chevron-left arrow-left"></i>
			<div className="hover"></div>
		</Link>
	);
};

export default NavItem;

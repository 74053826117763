import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const ServiceShowApplication = ({ match }) => {
	const {
		params: { applicationID },
	} = match;

	const [data, setData] = useState("");
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تفاصيل طلب الخدمة",
				breadcrumb: "الخدمات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/ServiceOrder/${applicationID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setData(response.data.data);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<div className="row">
				<div className="col-md-12">
					<div className="row">
						<div className="col-md-4">
							<div className="form-item">
								<label htmlFor="service">نوع الخدمة</label>
								<input type="text" readOnly={true} id="service" defaultValue={data.service_type} />
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-item">
								<label htmlFor="coast">رقم الهاتف</label>
								<input
									type="text"
									id="coast" readOnly={true}
									defaultValue={data.phone}
								/>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-item">
								<label htmlFor="email">البريد الالكتروني</label>
								<input type="email" readOnly={true} id="email" defaultValue={data.email} />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-4">
							<div className="form-item">
								<label htmlFor="name">اسم العميل</label>
								<input type="text" readOnly={true} id="name" defaultValue={data.name} />
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-item">
								<label htmlFor="coast">الملاحظات</label>
								<textarea rows={4} id="duration" readOnly={true} defaultValue={data.notes} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ServiceShowApplication;

import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";
import { updateMeta } from "../../../store/actions/metaAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const WorkCategoryShow = ({ match }) => {
	const {
		params: { categoryID },
	} = match;

	const [name, setName] = useState({ ar: "", en: "" });
	const [category, setCategory] = useState({});
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل نوع المنتج",
				breadcrumb: "توع المنتجات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(
				`${process.env.REACT_APP_API_URL}pages/work-type/sections/${categoryID}`,
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				setCategory(response.data.data.section);
				setName(response.data.data.section.titleObj);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		axios
			.put(
				`${process.env.REACT_APP_API_URL}pages/work-type/sections/${categoryID}`,
				{
					section: {
						...category,
						titleObj: name,
					},
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				toast.success("تم تحديث التصنيف بنجاح!");
				history.push("/workstypes/index");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="form-item">
							<label htmlFor="name">اسم التصنيف</label>
							<input
								type="text"
								id="name"
								required
								value={name?.ar ?? ""}
								onChange={e =>
									setName(v => {
										return { ...v, ar: e.target.value };
									})
								}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="name">اسم التصنيف</label>
							<input
								type="text"
								id="name"
								required
								value={name?.en ?? ""}
								onChange={e =>
									setName(v => {
										return { ...v, en: e.target.value };
									})
								}
							/>
						</div>
						<div className="form-item">
							<button className="btn btn-success">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default WorkCategoryShow;

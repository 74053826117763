import React, { useState, useEffect } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { Link } from "react-router-dom";
import Loader from "../../../components/shared/Loader";
import userPlaceholder from "../../../assets/images/placeholder.jpg";
import { updateMeta } from "../../../store/actions/metaAction";
import { v4 } from "uuid";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import { Col, Container, Row } from "reactstrap";

const SideInfoCreate = () => {
	const [sideInfo, setSideInfo] = useState([]);
	const [loading, setLoading] = useState(true);
	const [placeHolderData, setPlaceHolderData] = useState({});
	const auth = useSelector(state => state.auth.user);
	const [picture, setPicture] = useState(userPlaceholder);
	const [currentInfo, setCurrentInfo] = useState({
		id: v4(),
	});
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);
	const handlePicture = () => {
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
	};

	const updataComments = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/207`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				console.log({ ...response.data.data.data });
				setPlaceHolderData({ ...response.data.data.data });
				setSideInfo([...response.data.data.data.taps]);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	let editor = null;

	const onStart = () => {
		dispatch(
			updateMeta({
				title: " اضافة معلومة اضافية",
				breadcrumb: "المعلومات عن الموقع",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		updataComments();
	};

	const handleSubmit = e => {
		e.preventDefault();

		if (
			sideInfo.filter(Comment => Comment.id !== currentInfo?.id).length === 0 &&
			sideInfo.length > 0
		) {
			toast.error("الرجاء ادخال تعليق جديد");
			return;
		}
		const picture = document.querySelector("#picture");
		if (picture.files[0]) {
			setLoading(true);
			const data = new FormData();
			data.append("file", picture.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					
					axios
						.put(
							`${process.env.REACT_APP_API_URL}/posts/207`,
							{
								admin_id: auth.id,
								category_id: 1,
								data: {
									...placeHolderData,
									taps: [
										...sideInfo,
										{ ...currentInfo, image: response.data.data.name },
									],
								},
							},
							{
								headers: {
									Authorization: `Bearer ${cookie.get("token")}`,
									Accept: "application/json",
								},
							}
						)
						.then(function (response) {
							toast.success("تم تحديث الخدمة بنجاح!");
							setCurrentInfo({ id: v4() });
							updataComments();
							setLoading(false);
						})
						.catch(function (error) {
							toast.error("خطأ، برجاء المحاولة مره اخرى!");
							setLoading(false);
						});
				})
				.catch(error => {
                    console.log(error);
					toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
					setLoading(false);
				});
		} else {
			console.log("Test2");
			toast.error("برجاء اختيار الصورة أولا!");
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<form method="post" onSubmit={handleSubmit}>
				<Container>
					<Row>
						<Col
							xs={1}
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
								padding: "1rem",
							}}
						>
							<label htmlFor="Image"> الصورة</label>
							<input type="file" onChange={handlePicture} id="picture" />
							<img src={picture} alt="avatar" className="imagePreview" />
						</Col>
					</Row>
					<Row>
						<Col
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
								padding: "1rem",
							}}
						>
							<label htmlFor="TitelAR"> العنوان</label>
							<input
								type="text"
								id="TitelAR"
								style={{ all: "revert" }}
								value={currentInfo?.title?.ar ?? ""}
								placeholder="ادخل العنوان هنا"
								onChange={e =>
									setCurrentInfo({
										...currentInfo,
										title: { ...currentInfo.title, ar: e.target.value },
									})
								}
							/>
						</Col>
					</Row>
					<Row>
						<Col
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
								padding: "1rem",
							}}
						>
							<label htmlFor="TitelEN"> العنوان بالانجليزية</label>
							<input
								type="text"
								id="TitelEN"
								placeholder="ادخل العنوان هنا"
								style={{ all: "revert" }}
								value={currentInfo?.title?.en ?? ""}
								onChange={e =>
									setCurrentInfo({
										...currentInfo,
										title: { ...currentInfo.title, en: e.target.value },
									})
								}
							/>
						</Col>
					</Row>
					<Row>
						<Col
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
								padding: "1rem",
							}}
						>
							<label htmlFor="DescriptionAR">الوصف</label>
							<CKEditor
								onReady={editor => {
									editor.ui
										.getEditableElement()
										.parentElement.insertBefore(
											editor.ui.view.toolbar.element,
											editor.ui.getEditableElement()
										);
									editor = editor;
								}}
								onError={({ willEditorRestart }) => {
									if (willEditorRestart) {
										editor.ui.view.toolbar.element.remove();
									}
								}}
								onChange={(event, editor) => {
									const data = editor.getData();
									setCurrentInfo({
										...currentInfo,
										description: {
											...currentInfo.description,
											ar: data,
										},
									});
								}}
								editor={DecoupledEditor}
								config={{
									contentsLangDirection: "rtl",
									language: "ar",
								}}
							/>
						</Col>
					</Row>
					<Row>
						<Col
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-start",
								alignItems: "flex-start",
								gap: "1rem",
								padding: "1rem",
							}}
						>
							<label htmlFor="DescriptionEn">الوصف بالانجليزية</label>
							<CKEditor
								onReady={editor => {
									editor.ui
										.getEditableElement()
										.parentElement.insertBefore(
											editor.ui.view.toolbar.element,
											editor.ui.getEditableElement()
										);
									editor = editor;
								}}
								onError={({ willEditorRestart }) => {
									if (willEditorRestart) {
										editor.ui.view.toolbar.element.remove();
									}
								}}
								onChange={(event, editor) => {
									const data = editor.getData();
									setCurrentInfo({
										...currentInfo,
										description: {
											...currentInfo.description,
											en: data,
										},
									});
								}}
								editor={DecoupledEditor}
								config={{
									contentsLangDirection: "ltr",
									language: "en",
								}}
							/>
						</Col>
					</Row>
				</Container>

				<div className="form-item mt-3">
					<button className="bt bt-success btn-block">ارسال</button>
				</div>
			</form>
		</section>
	);
};

export default SideInfoCreate;

import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import Notifications from "./Notifications";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Layout = props => {
	const meta = useSelector(state => state.meta);

	return (
		<div className="main-wrapper">
			<Sidebar />
			<div className="content-wrapper" id="content-wrapper">
				<Header breadcrumbTitle={meta.breadcrumb} />
				<Notifications />
				<div className="content">
					<div className="page-meta">
						<h1 className="main-title">{meta.title}</h1>
						{meta.button.show && (
							<Link to={meta.button.link} className="bt bt-success">
								{meta.button.text}
							</Link>
						)}
					</div>
					{props.children}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Layout;

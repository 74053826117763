import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Loader from "../../../components/shared/Loader";
import { updateMeta } from "../../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const SideInfoIndex = () => {
	const [sideInfo, setSideInfo] = useState([]);
	const [loading, setLoading] = useState(true);
	const [placeHolderData, setPlaceHolderData] = useState({});

	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "جميع البيانات الاضافية",
				breadcrumb: "المعلومات عن الموقع",
				button: {
					show: true,
					text: "اضافة بيانات جديد",
					link: "/aboutPage/sideinfo/create",
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/207`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				console.log({ ...response.data.data.data });
				setPlaceHolderData({ ...response.data.data.data });
				setSideInfo([...response.data.data.data.taps]);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const columns = [
		{
			name: "ID",
			selector: "id",
			sortable: true,
		},
		{
			name: "العنوان",
			selector: "title",
			sortable: true,
		},
		{
			name: "",
			selector: "settings",
			cell: row => (
				<ul className="list-inline settingsList">
					<li className="list-inline-item">
						<Link
							className="bt bt-sm bt-success"
							to={`/aboutPage/sideinfo/show/${row.id}`}
						>
							<i className="fas fa-edit"></i>
						</Link>
					</li>
					<li className="list-inline-item">
						<button
							className="bt bt-sm bt-danger"
							onClick={() => handleConfrimDelete(row.id)}
						>
							<i className="fas fa-trash"></i>
						</button>
					</li>
				</ul>
			),
		},
	];

	const deletSideInfo = InfoID => {
		const data = [...sideInfo.filter(info => info.id !== InfoID)];

		axios
			.put(
				`${process.env.REACT_APP_API_URL}/posts/207`,
				{
					category_id: 1,
					admin_id: 1,
					data: { ...placeHolderData, taps: [...data] },
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				console.log(response);
				setPlaceHolderData({ ...response.data.data.data });
				setSideInfo([...response.data.data.data.taps]);
				toast.success("تم حذف المعلومة بنجاح");
			})
			.catch(function (error) {
				console.log(error);
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handleConfrimDelete = articleID => {
		confirmAlert({
			title: "تأكيد الحذف",
			message: "هل أنت متأكد من رغبتك في الحذف؟",
			buttons: [
				{
					label: "نعم",
					onClick: () => deletSideInfo(articleID),
				},
				{
					label: "لا",
					onClick: () => console.log("No"),
				},
			],
		});
	};

	return (
		<section>
			{loading && <Loader />}
			<div className="datatable">
				<DataTableExtensions
					columns={columns}
					data={[
						...sideInfo.map(info => {
							return { ...info, title: info?.title?.ar ?? "" };
						})
					]}
				>
					<DataTable
						noHeader
						defaultSortField="id"
						defaultSortAsc={false}
						pagination
						highlightOnHover
						direction="rtl"
						striped
					/>
				</DataTableExtensions>
			</div>
		</section>
	);
};

export default SideInfoIndex;

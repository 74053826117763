import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import Loader from "../../components/shared/Loader";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { useDropzone } from "react-dropzone";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Container, Col, Row } from "reactstrap";
import { updateMeta } from "../../store/actions/metaAction";
import { v4 } from "uuid";
const BlogCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const parser = new DOMParser();
	const history = useHistory();
	const [title, setTitle] = useState({});
	const [description, setDescription] = useState({});
	const [qouts, setqouts] = useState({});
	const [contant, setContant] = useState({});
	const [imageArray, setImageArray] = useState([]);
	const [picture, setPicture] = useState({
		image: userPlaceholder,
		blob: true,
	});
	const [loading, setLoading] = useState(true);
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState(0);
	const dispatch = useDispatch();
	const [tags, setTags] = useState([]);
	const [links, setLinks] = useState([]);
	const [files, setFiles] = useState([]);
	const [currentTag, setCurrentTag] = useState("");
	const [currentLink, setCurrentLink] = useState("");
	const [currentLinkType, setCurrentLinkType] = useState("fb");
	const [midContentObj, setMidcontentObj] = useState({
		image: { image: userPlaceholder, blob: true },
		content: {
			ar: "",
			en: "",
		},
	});
	let editor = null;

	useEffect(() => {
		onStart();
	}, []);

	const handleOnCurrentTagChange = e => {
		setCurrentTag(e.target.value);
	};
	const handleOnCurrentLinkChange = e => {
		setCurrentLink(e.target.value);
	};
	const handleImageUpload = async e => {
		if (!files.length) {
			toast.error("برجاء إختيار صورة اولا");
			return;
		}
		e.preventDefault();
		setLoading(true);
		for (let i = 0; i < files.length; i++) {
			const formData = new FormData();
			formData.append(`file`, files[i]);
			await axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(function (responseImage) {
					imageArray.push(responseImage.data.data.file.path);
					setImageArray(imageArray);
				})
				.catch(function (error) {
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};
	const handleTag = e => {
		const selectedtag = { id: v4(), value: currentTag };

		if (e.key === "Enter") {
			if (tags.filter(tag => tag.value === selectedtag.value).length > 0) {
				toast.warn("يرجاء اضافة كلمة دالية جديدة");
				return;
			}
			setTags([...tags, selectedtag]);
			setCurrentTag("");
		}
	};
	const handleLink = e => {
		const selectedlink = {
			id: v4(),
			value: currentLink,
			type: currentLinkType,
		};

		if (e.key === "Enter") {
			if (links.length > 6) {
				toast.warn("اقصى  حد لينكات هو 6 لينكات ");
				return;
			}
			if (
				links.filter(
					link =>
						link.value === selectedlink.value && link.type === selectedlink.type
				).length > 0
			) {
				toast.warn("يرجاء عدم اضافة نفس اللينك مرتين");
				return;
			}
			setLinks([...links, selectedlink]);
			setCurrentTag("");
		}
	};

	const deleteImage = (event, img) => {
		event.preventDefault();
		const arr = imageArray.filter(item => {
			return item !== img;
		});
		setImageArray(arr);
	};

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة مقال جديد",
				breadcrumb: "المدونة",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/categories`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setCategories(response.data.data.categories);
				setLoading(false);

				// axios
				// 	.get(`${process.env.REACT_APP_API_URL}/tags`, {
				// 		headers: {
				// 			Authorization: `Bearer ${cookie.get("token")}`,
				// 			Accept: "application/json",
				// 		},
				// 	})
				// 	.then(response => {
				// 		setTagsOptions([
				// 			...response.data.data.map(option => ({
				// 				value: option.id,
				// 				label: option.name,
				// 			})),
				// 		]);
				// 		setLoading(false);
				// 	})
				// 	.catch(function (error) {
				// 		toast.error("خطأ، برجاء المحاولة لاحقا!");
				// 	});
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const handlePicture = e => {
		const fileInput = document.querySelector(`#${e.target.id}`);
		e.target.id === "picture"
			? setPicture({
					image: URL.createObjectURL(fileInput.files[0]),
					blob: true,
			  })
			: setMidcontentObj({
					...midContentObj,
					image: {
						image: URL.createObjectURL(fileInput.files[0]),
						blob: true,
					},
			  });
	};

	// const handleTags = options => {
	// 	setSelectTags([...options.map(option => option.value)]);
	// };
	const thumbs = files.map(file => (
		<div className="thumb" key={file.name}>
			<div className="thumbInner">
				<img src={file.preview} className="img" />
			</div>
		</div>
	));
	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: false,
		onDrop: acceptedFiles => {
			setFiles(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});
	const handleSubmit = async e => {
		e.preventDefault();
		const picture = document.querySelector("#picture");
		const Midpicture = document.querySelector("#midContentImg");
		if (picture.files[0] && Midpicture.files[0]) {
			setLoading(true);
			const Miandata = new FormData();
			Miandata.append("file", picture.files[0]);
			const midData = new FormData();
			midData.append("file", Midpicture.files[0]);

			Promise.all([
				await axios.post(`${process.env.REACT_APP_API_URL}/upload`, Miandata, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				}),
				await axios.post(`${process.env.REACT_APP_API_URL}/upload`, midData, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				}),
			])
				.then(response => {
					toast.info("تم الانتهاء من الجزء الاول من عملية اضافة البيانات!");
					const data = {
						title: title,
						category: selectedCategory,
						description: description,
						image: {
							image: response[0].data.data.file.path,
							blob: false,
						},
						tags: [...tags.map(tag => tag.value)],
						contents: {
							page_contentObj: contant,
							titleObj: title,
							images: imageArray,
							qouts: qouts,
							descriptionInBrev: {
								ar:
									parser.parseFromString(description.ar, "text/html").body
										.children[0].innerText + "...",
								en:
									parser.parseFromString(description.en, "text/html").body
										.children[0].innerText + "...",
							},
							middle_content: {
								...midContentObj,
								image: {
									image: response[1].data.data.file.path,
									blob: false,
								},
							},
						},
					};
					axios
						.post(`${process.env.REACT_APP_API_URL}/blogs`, data, {
							headers: {
								Authorization: `Bearer ${cookie.get("token")}`,
								Accept: "application/json",
							},
						})
						.then(function (response) {
							history.push("/blog/index");
							toast.success("تم اضافة المدونة بنجاح!");
							setLoading(false);
						})
						.catch(function (error) {
							toast.error("خطأ، برجاء المحاولة مره اخرى!");
							setLoading(false);
						});
				})
				.catch(error => {
					toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
					setLoading(false);
				});
		} else {
			toast.error("برجاء اختيار الصورة أولا!");
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<div className="row">
				<div className="col-md-9">
					<div className="card">
						<div className="card-body">
							<div className="form-item">
								<label htmlFor="category">التصنيف</label>
								<select
									id="category"
									onChange={e => setSelectedCategory(e.target.value)}
								>
									<option value="0">اختر التصنيف</option>
									{categories.map(category => (
										<option value={category.id} key={category.id}>
											{category.title.ar ?? category.title.en ?? ""}
										</option>
									))}
								</select>
							</div>
							<div className="form-item">
								<label htmlFor="titleAR">العنوان</label>
								<input
									type="text"
									id="titleAR"
									required
									onChange={e =>
										setTitle(v => {
											return { ...v, ar: e.target.value };
										})
									}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="titleEn">العنوان باللغة الإنجليزية</label>
								<input
									type="text"
									id="titleEn"
									required
									onChange={e =>
										setTitle(v => {
											return { ...v, en: e.target.value };
										})
									}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="qouteAR">اقتبس</label>
								<input
									type="text"
									id="qouteAR"
									required
									onChange={e =>
										setqouts(v => {
											return { ...v, ar: e.target.value };
										})
									}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="qouteEn">اقتبس باللغة الإنجليزية</label>
								<input
									type="text"
									id="qouteEn"
									required
									onChange={e =>
										setqouts(v => {
											return { ...v, en: e.target.value };
										})
									}
								/>
							</div>
							<div className="form-item">
								<Container>
									<Row>
										<Col xs={12} md={8}>
											<div className="inputfildeContainer">
												<label htmlFor="MidContent">المحتوي الوصفي</label>
												<CKEditor
													id={"MidContent"}
													data={midContentObj.content.ar ?? ""}
													onReady={editor => {
														editor.ui
															.getEditableElement()
															.parentElement.insertBefore(
																editor.ui.view.toolbar.element,
																editor.ui.getEditableElement()
															);
														editor = editor;
													}}
													onError={({ willEditorRestart }) => {
														if (willEditorRestart) {
															editor.ui.view.toolbar.element.remove();
														}
													}}
													onChange={(event, editor) => {
														const data = editor.getData();
														setMidcontentObj({
															...midContentObj,
															content: { ...midContentObj.content, ar: data },
														});
													}}
													editor={DecoupledEditor}
													config={{
														contentsLangDirection: "rtl",
														language: "ar",
													}}
												/>
											</div>
											<div className="inputfildeContainer">
												<label htmlFor="MidContentEn">
													المحتوي الوصفي بالانجليزية
												</label>
												<CKEditor
													id={"MidContentEn"}
													data={midContentObj.content.en ?? ""}
													onReady={editor => {
														editor.ui
															.getEditableElement()
															.parentElement.insertBefore(
																editor.ui.view.toolbar.element,
																editor.ui.getEditableElement()
															);
														editor = editor;
													}}
													onError={({ willEditorRestart }) => {
														if (willEditorRestart) {
															editor.ui.view.toolbar.element.remove();
														}
													}}
													onChange={(event, editor) => {
														const data = editor.getData();
														setMidcontentObj({
															...midContentObj,
															content: { ...midContentObj.content, en: data },
														});
													}}
													editor={DecoupledEditor}
													config={{
														contentsLangDirection: "ltr",
														language: "en",
													}}
												/>
											</div>
										</Col>
										<Col xs={12} md={4}>
											<label htmlFor="midContentImg">
												{" "}
												صورة المحتوي الوصفي{" "}
											</label>
											<input
												type="file"
												src={midContentObj.image}
												alt=""
												onChange={handlePicture}
												id="midContentImg"
											/>
											<img
												src={
													midContentObj.image.blob
														? midContentObj.image.image
														: `${process.env.REACT_APP_MAIN_URL}${midContentObj.image.image}`
												}
												alt="avatar"
												className="imagePreview"
											/>
										</Col>
									</Row>
								</Container>
							</div>

							<div className="form-item">
								<label htmlFor="descriptionAr">الوصف</label>
								<CKEditor
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setDescription(v => {
											return { ...v, ar: data };
										});
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "rtl",
										language: "ar",
									}}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="descriptionEn">الوصف باللغة الإنجليزية</label>
								<CKEditor
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setDescription(v => {
											return { ...v, en: data };
										});
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "ltr",
										language: "en",
									}}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="contantAr">المحتوي</label>
								<CKEditor
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setContant(v => {
											return { ...v, ar: data };
										});
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "rtl",
										language: "ar",
									}}
								/>
							</div>
							<div className="form-item">
								<label htmlFor="contantEn">المحتواي بالغة الانجليزية</label>
								<CKEditor
									onReady={editor => {
										editor.ui
											.getEditableElement()
											.parentElement.insertBefore(
												editor.ui.view.toolbar.element,
												editor.ui.getEditableElement()
											);
										editor = editor;
									}}
									onError={({ willEditorRestart }) => {
										if (willEditorRestart) {
											editor.ui.view.toolbar.element.remove();
										}
									}}
									onChange={(event, editor) => {
										const data = editor.getData();
										setContant(v => {
											return { ...v, en: data };
										});
									}}
									editor={DecoupledEditor}
									config={{
										contentsLangDirection: "ltr",
										language: "en",
									}}
								/>
							</div>
							<div className="form-item">
								<div className="dropzoneContainer">
									<div {...getRootProps({ className: "dropzone" })}>
										<input {...getInputProps()} />
										<p>اضغط هنا لرفع الصورة الداخلية</p>
									</div>
									<aside className="thumbsContainer">{thumbs}</aside>
									<button
										onClick={handleImageUpload}
										type="button"
										className="bt bt-success"
									>
										رفع الصورة
									</button>
								</div>
								<div className="row">
									{imageArray.map(img => (
										<div className="col-md-2" key={img}>
											<div className="image-preview">
												<img
													src={`${process.env.REACT_APP_MAIN_URL}/${img}`}
													alt={img}
													className="imgList"
												/>
												<button onClick={e => deleteImage(e, img)}>
													<i className="fas fa-trash"></i>
												</button>
											</div>
										</div>
									))}
								</div>
							</div>
							<div className="form-item">
								<label htmlFor="tagesInput">Tags</label>
								<input
									type="text"
									onChange={handleOnCurrentTagChange}
									onKeyPress={handleTag}
									value={currentTag}
									id="tagesInput"
								/>
								<div className="TagsContainer">
									{tags?.map(tag => (
										<p
											key={`${tag.id}`}
											style={{
												position: "relative",
												display: "inline-block",
												margin: ".5rem",
												padding: ".5rem 1rem",
												borderRadius: "1rem",
												cursor: "default",
												color: "white",
												background: "rgb(0,198,187)",
												background:
													"linear-gradient(158deg, rgba(0,198,187,1) -500%, rgba(0,138,131,1) 42%, rgba(0,98,179,1) 58%, rgba(0,42,77,1) 95%)",
											}}
										>
											#{tag.value}
											<span
												style={{
													marginRight: "1rem",
													cursor: "pointer",
													color: "#931515",
												}}
												onClick={() =>
													setTags([
														...tags.filter(
															targetedTag => targetedTag.id !== tag.id
														),
													])
												}
											>
												<i className="fas fa-trash" />
											</span>
										</p>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-3">
					<div className="card">
						<div className="card-body">
							<div className="form-item">
								<input type="file" onChange={handlePicture} id="picture" />
								<img
									src={
										picture.blob
											? picture.image
											: `${process.env.REACT_APP_MAIN_URL}${picture.image}`
									}
									alt="avatar"
									className="imagePreview"
								/>
							</div>
						</div>
					</div>
					<div className="form-item mt-3">
						<button className="bt bt-success btn-block" onClick={handleSubmit}>
							ارسال
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BlogCreate;

import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { metaReducer } from "./metaReducer";

const allReducers = combineReducers({
	auth: userReducer,
	meta: metaReducer,
});

export default allReducers;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";
import { updateMeta } from "../../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const WorkCategoryCreate = () => {
	const history = useHistory();
	const [name, setName] = useState({});
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة نوع منتج جديد",
				breadcrumb: "المنتجات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		axios
			.post(
				`${process.env.REACT_APP_API_URL}pages/work-type/sections/`,
				{
					section: {
						title: name.en ?? "",
						titleObj: name,
					},
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/workstypes/index");
				toast.success("تم اضافة التصنيف بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="form-item">
							<label htmlFor="nameAr">اسم التصنيف</label>
							<input
								type="text"
								id="nameAr"
								required
								onChange={e =>
									setName(v => {
										return { ...v, ar: e.target.value };
									})
								}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="nameEn">اسم التصنيف بالانجليزية</label>
							<input
								type="text"
								id="nameEn"
								required
								onChange={e =>
									setName(v => {
										return { ...v, en: e.target.value };
									})
								}
							/>
						</div>
						<div className="form-item">
							<button className="bt bt-success">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default WorkCategoryCreate;

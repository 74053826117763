import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import cookie from "js-cookie";
import { useHistory } from "react-router-dom";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const NewsletterSend = () => {
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "إرسال رسالة للمشتركين",
				breadcrumb: "القائمة البريدية",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (!message) {
			toast.error("برجاء كتابة رسالة");
		} else {
			setLoading(true);
			axios
				.post(
					`${process.env.REACT_APP_API_URL}/send_emails`,
					{
						message,
					},
					{
						headers: {
							Authorization: `Bearer ${cookie.get("token")}`,
							Accept: "application/json",
						},
					}
				)
				.then(function (response) {
					history.push("/newsletter/index");
					toast.success("تم إرسال الرسالة بنجاح!");
					setLoading(false);
				})
				.catch(function (error) {
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="form-item">
							<label htmlFor="name">الرسالة</label>
							<textarea
								style={{ width: "100%" }}
								rows={5}
								name="message"
								id="message"
								onChange={e => setMessage(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div className="form-item">
					<button className="bt bt-success">ارسال</button>
				</div>
			</form>
		</section>
	);
};

export default NewsletterSend;

import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const ServicesIndex = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "جميع الخدمات",
				breadcrumb: "الخدمات",
				button: {
					show: true,
					text: "اضافة خدمة جديدة",
					link: "/services/create",
				},
			})
		);

		axios
			.get(`${process.env.REACT_APP_API_URL}/pages/our-services-posts`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setData(response.data.data.page.sections);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
				setLoading(false);
			});
	};

	const handleDelete = postID => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/pages/our-services-posts/sections/${postID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				onStart();
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<section>
				<div className="row">
					{data.map(post => (
						<div className="col-md-3" key={post.slug}>
							<div className="serviceItem">
								<button onClick={() => handleDelete(post.slug)}>
									<i className="fas fa-trash"></i>
								</button>
								<Link to={`/services/show/${post.slug}`}>
									<img
										src={`${process.env.REACT_APP_MAIN_URL}/${post.icon}`}
										alt={post.titleObj.ar ?? ""}
									/>
									<h1>{post.titleObj?.ar ?? ""}</h1>
									<p>{post.descriptionObj?.ar ?? ""}</p>
								</Link>
							</div>
						</div>
					))}
				</div>
			</section>
		</section>
	);
};

export default ServicesIndex;

import { BrowserRouter as Router, Switch } from "react-router-dom";
import GeustRoute from "./helpers/authentication/geustRoute";
import Layout from "./components/layout/Layout";
import AuthRoute from "./helpers/authentication/authRoute";
import Login from "./views/authentication/Login";
import Home from "./views/Home";
import ModeratorsIndex from "./views/moderators/ModeratorsIndex";
import ModeratorsCreate from "./views/moderators/ModeratorsCreate";
import ModeratorShow from "./views/moderators/ModeratorShow";
import BlogIndex from "./views/blog/BlogIndex";
import BlogCreate from "./views/blog/BlogCreate";
import BlogShow from "./views/blog/BlogShow";
import CategoriesIndex from "./views/blog/categories/CategoriesIndex";
import CategoriesCreate from "./views/blog/categories/CategoriesCreate";
import CategoriesShow from "./views/blog/categories/CategoriesShow";
import TagIndex from "./views/blog/tag/TagIndex";
import TagCreate from "./views/blog/tag/TagCreate";
import TagShow from "./views/blog/tag/TagShow";
import ServicesIndex from "./views/services/ServicesIndex";
import ServicesCreate from "./views/services/ServicesCreate";
import ServiceShow from "./views/services/ServiceShow";
import ServicesApplications from "./views/services/ServicesApplications";
import ServiceShowApplication from "./views/services/ServiceShowApplication";
import WorksIndex from "./views/works/WorksIndex";
import WorksCreate from "./views/works/WorksCreate";
import WorkShow from "./views/works/WorkShow";

import WorkCategoryIndex from "./views/works/workCategory/WorkCategoryIndex";
import WorkCategoryCreate from "./views/works/workCategory/WorkCategoryCreate";
import WorkCategoryShow from "./views/works/workCategory/WorkCategoryShow";

import PartnersIndex from "./views/partners/PartnersIndex";
import PartnersCreate from "./views/partners/PartnersCreate";
import PartnersShow from "./views/partners/PartnersShow";
import ContactIndex from "./views/contact/ContactIndex";
import ContactShow from "./views/contact/ContactShow";
import SliderIndex from "./views/slider/SliderIndex";
import SliderCreate from "./views/slider/SliderCreate";
import SliderShow from "./views/slider/SliderShow";

import GalleryIndex from "./views/gallery/GalleryIndex";
import GalleryCreate from "./views/gallery/GalleryCreate";
import GalleryShow from "./views/gallery/GalleryShow";

import LinksIndex from "./views/footerLinks/LinksIndex";
import LinksCreate from "./views/footerLinks/LinksCreate";
import LinksShow from "./views/footerLinks/LinksShow";
import CentersIndex from "./views/centers/CentersIndex";
import CentersCreate from "./views/centers/CentersCreate";
import CentersShow from "./views/centers/CentersShow";

import NewsletterIndex from "./views/newsletter/NewsletterIndex";
import NewsletterCreate from "./views/newsletter/NewsletterCreate";
// import About from "./views/About";
import Settings from "./views/Settings";

import AdvantagesCreate from "./views/aboutPage/advantages/AdvantagesCreate";
import AdvantagesIndex from "./views/aboutPage/advantages/AdvantagesIndex";
import AdvantagesShow from "./views/aboutPage/advantages/AdvantagesShow";

import StatisticsCreate from "./views/aboutPage/statistics/StatisticsCreate";
import StatisticsIndex from "./views/aboutPage/statistics/StatisticsIndex";
import StatisticsShow from "./views/aboutPage/statistics/StatisticsShow";

import QuestionsCreate from "./views/aboutPage/questions/QuestionsCreate";
import QuestionsIndex from "./views/aboutPage/questions/QuestionsIndex";
import QuestionsShow from "./views/aboutPage/questions/QuestionsShow";

import HeaderCreate from "./views/aboutPage/header/HeaderCreate";
import HeaderIndex from "./views/aboutPage/header/HeaderIndex";
import HeaderShow from "./views/aboutPage/header/HeaderShow";
import BannersIndex from "./views/banners/BannersIndex";
import BannersCreate from "./views/banners/BannersCreate";
import BannersShow from "./views/banners/BannersShow";
import AutomotivePostsCreate from "./views/automotivePage/automotivePosts/AutomotivePostsCreate";
import AutomotivePostsIndex from "./views/automotivePage/automotivePosts/AutomotivePostsIndex";
import AutomotivePostsShow from "./views/automotivePage/automotivePosts/AutomotivePostsShow";

import PostsCreate from "./views/industryPage/posts/PostsCreate";
import PostsIndex from "./views/industryPage/posts/PostsIndex";
import PostsShow from "./views/industryPage/posts/PostsShow";

import VideoCreate from "./views/industryPage/video/VideoCreate";
import VideoIndex from "./views/industryPage/video/VideoIndex";
import VideoShow from "./views/industryPage/video/VideoShow";
import AutomotiveVideoCreate from "./views/automotivePage/automotiveVideo/AutomotiveVideoCreate";
import AutomotiveVideoIndex from "./views/automotivePage/automotiveVideo/AutomotiveVideoIndex";
import AutomotiveVideoShow from "./views/automotivePage/automotiveVideo/AutomotiveVideoShow";
import AutomotiveSectionsCreate from "./views/automotivePage/automotiveSections/AutomotiveSectionsCreate";
import AutomotiveSectionsIndex from "./views/automotivePage/automotiveSections/AutomotiveSectionsIndex";
import AutomotiveSectionsShow from "./views/automotivePage/automotiveSections/AutomotiveSectionsShow";

import AutomotiveHeadersCreate from "./views/automotivePage/automotiveHeaders/AutomotiveHeadersCreate";
import AutomotiveHeadersShow from "./views/automotivePage/automotiveHeaders/AutomotiveHeadersShow";
import AutomotiveHeadersIndex from "./views/automotivePage/automotiveHeaders/AutomotiveHeadersIndex";
import BenefitsHeaderCreate from "./views/benefits/benefitsHeader/BenefitsHeaderCreate";
import BenefitsHeaderIndex from "./views/benefits/benefitsHeader/BenefitsHeaderIndex";
import BenefitsHeaderShow from "./views/benefits/benefitsHeader/BenefitsHeaderShow";
import BenefitsVideoCreate from "./views/benefits/benefitsVideo/BenefitsVideoCreate";
import BenefitsVideoIndex from "./views/benefits/benefitsVideo/BenefitsVideoIndex";
import BenefitsVideoShow from "./views/benefits/benefitsVideo/BenefitsVideoShow";
import AutomotiveSplitersCreate from "./views/automotivePage/automotiveSpliters/AutomotiveSplitersCreate";
import AutomotiveSplitersIndex from "./views/automotivePage/automotiveSpliters/AutomotiveSplitersIndex";
import AutomotiveSplitersShow from "./views/automotivePage/automotiveSpliters/AutomotiveSplitersShow";
import SplitersCreate from "./views/spliters/SplitersCreate";
import SplitersIndex from "./views/spliters/SplitersIndex";
import SplitersShow from "./views/spliters/SplitersShow";
import MainVideoShow from "./views/mainVideo/MainVideoShow";
import MainVideoIndex from "./views/mainVideo/MainVideoIndex";
import MainVideoCreate from "./views/mainVideo/MainVideoCreate";
import WorksVideosShow from "./views/works/worksvideos/WorksVideosShow";
import WorksVideosCreate from "./views/works/worksvideos/WorksVideosCreate";
import WorksVideosIndex from "./views/works/worksvideos/WorksVideosIndex";
import InformationShow from "./views/aboutPage/information/InformationShow";

import ProductOneHeaderIndex from "./views/productOnePage/productOneHeader/ProductOneHeaderIndex";
import ProductOneHeaderCreate from "./views/productOnePage/productOneHeader/ProductOneHeaderCreate";
import ProductOneHeaderShow from "./views/productOnePage/productOneHeader/ProductOneHeaderShow";

import ProductOnePostsIndex from "./views/productOnePage/productOnePosts/ProductOnePostsIndex";
import ProductOnePostsCreate from "./views/productOnePage/productOnePosts/ProductOnePostsCreate";
import ProductOnePostsShow from "./views/productOnePage/productOnePosts/ProductOnePostsShow";

import ProductTwoHeaderIndex from "./views/productTwoPage/productTwoHeader/ProductTwoHeaderIndex";
import ProductTwoHeaderCreate from "./views/productTwoPage/productTwoHeader/ProductTwoHeaderCreate";
import ProductTwoHeaderShow from "./views/productTwoPage/productTwoHeader/ProductTwoHeaderShow";

import ProductTwoPostsIndex from "./views/productTwoPage/productTwoPosts/ProductTwoPostsIndex";
import ProductTwoPostsCreate from "./views/productTwoPage/productTwoPosts/ProductTwoPostsCreate";
import ProductTwoPostsShow from "./views/productTwoPage/productTwoPosts/ProductTwoPostsShow";

import ProductThreeHeaderIndex from "./views/productThreePage/productThreeHeader/ProductThreeHeaderIndex";
import ProductThreeHeaderCreate from "./views/productThreePage/productThreeHeader/ProductThreeHeaderCreate";
import ProductThreeHeaderShow from "./views/productThreePage/productThreeHeader/ProductThreeHeaderShow";

import ProductThreePostsIndex from "./views/productThreePage/productThreePosts/ProductThreePostsIndex";
import ProductThreePostsCreate from "./views/productThreePage/productThreePosts/ProductThreePostsCreate";
import ProductThreePostsShow from "./views/productThreePage/productThreePosts/ProductThreePostsShow";

import AchivmentIndex from "./views/achivment/AchivmentIndex";
import AchivmentCreate from "./views/achivment/AchivmentCreate";
import AchivmentShow from "./views/achivment/AchivmentShow";

import SideInfoIndex from "./views/aboutPage/sideInfo/SideInfoIndex";
import SideInfoCreate from "./views/aboutPage/sideInfo/SideInfoCreate";
import SideInfoShow from "./views/aboutPage/sideInfo/SideInfoShow";

import CommentsIndex from "./views/Comments/CommentsIndex";
import CommentsCreate from "./views/Comments/CommentsCreate";
import CommentsShow from "./views/Comments/CommentsShow";

function App() {
	return (
		<Router>
			<Switch>
				<GeustRoute path="/login" component={Login} />
				<Layout>
					<AuthRoute path="/" exact component={Home} />
					<AuthRoute path="/dashboard" component={Home} />
					<AuthRoute path="/moderators/index" component={ModeratorsIndex} />
					<AuthRoute path="/moderators/create" component={ModeratorsCreate} />
					<AuthRoute
						path="/moderators/show/:moderatorID"
						component={ModeratorShow}
					/>
					<AuthRoute path="/blog/index" component={BlogIndex} />
					<AuthRoute path="/blog/create" component={BlogCreate} />
					<AuthRoute path="/blog/show/:blogID" component={BlogShow} />
					<AuthRoute path="/categories/index" component={CategoriesIndex} />
					<AuthRoute path="/categories/create" component={CategoriesCreate} />
					<AuthRoute
						path="/categories/show/:categoryID"
						component={CategoriesShow}
					/>
					<AuthRoute path="/tag/index" component={TagIndex} />
					<AuthRoute path="/tag/create" component={TagCreate} />
					<AuthRoute path="/tag/show/:centerID" component={TagShow} />
					<AuthRoute path="/services/index" component={ServicesIndex} />
					<AuthRoute path="/services/create" component={ServicesCreate} />
					<AuthRoute path="/services/show/:serviceID" component={ServiceShow} />
					<AuthRoute
						path="/services/applications"
						component={ServicesApplications}
					/>
					<AuthRoute
						path="/services/application/:applicationID"
						component={ServiceShowApplication}
					/>
					<AuthRoute path="/works/index" component={WorksIndex} />
					<AuthRoute path="/works/create" component={WorksCreate} />
					<AuthRoute path="/works/show/:workID" component={WorkShow} />
					<AuthRoute path="/workstypes/index" component={WorkCategoryIndex} />
					<AuthRoute path="/workstypes/create" component={WorkCategoryCreate} />
					<AuthRoute
						path="/workstypes/show/:categoryID"
						component={WorkCategoryShow}
					/>

					<AuthRoute path="/partners/index" component={PartnersIndex} />
					<AuthRoute path="/partners/create" component={PartnersCreate} />
					<AuthRoute
						path="/partners/show/:partnerID"
						component={PartnersShow}
					/>
					<AuthRoute path="/orders/index" component={ContactIndex} />
					<AuthRoute path="/orders/show/:contactID" component={ContactShow} />
					<AuthRoute path="/newsletter/index" component={NewsletterIndex} />
					<AuthRoute path="/newsletter/send" component={NewsletterCreate} />
					{/* <AuthRoute path="/about/show" component={About} /> */}
					<AuthRoute path="/settings" component={Settings} />
					<AuthRoute path="/slider/index" component={SliderIndex} />
					<AuthRoute path="/slider/create" component={SliderCreate} />
					<AuthRoute path="/slider/show/:slideID" component={SliderShow} />

					<AuthRoute path="/gallery/index" component={GalleryIndex} />
					<AuthRoute path="/gallery/create" component={GalleryCreate} />
					<AuthRoute path="/gallery/show/:imageID" component={GalleryShow} />

					<AuthRoute path="/banners/index" component={BannersIndex} />
					<AuthRoute path="/banners/create" component={BannersCreate} />
					<AuthRoute path="/banners/show/:bannerID" component={BannersShow} />

					<AuthRoute path="/footerLinks/index" component={LinksIndex} />
					<AuthRoute path="/footerLinks/create" component={LinksCreate} />
					<AuthRoute path="/footerLinks/show/:linkID" component={LinksShow} />

					<AuthRoute path="/centers/index" component={CentersIndex} />
					<AuthRoute path="/centers/create" component={CentersCreate} />
					<AuthRoute path="/centers/show/:centerID" component={CentersShow} />

					{/* Achivment Section */}

					<AuthRoute path="/achivment/index" component={AchivmentIndex} />
					<AuthRoute path="/achivment/create" component={AchivmentCreate} />
					<AuthRoute
						path="/achivment/show/:centerID"
						component={AchivmentShow}
					/>

					{/* Achivment End Here */}

					{/* Comments Section */}

					<AuthRoute path="/Comments/index" component={CommentsIndex} />
					<AuthRoute path="/Comments/create" component={CommentsCreate} />
					<AuthRoute path="/Comments/show/:centerID" component={CommentsShow} />

					{/* Comments End Here */}
					{/* //////////////////////////////////////////////////////////////////////// */}
					{/* //////////////////////////////////////////////////////////////////////// */}
					{/* //////////////////////////////////////////////////////////////////////// */}
					<AuthRoute
						path="/aboutPage/statistics/create"
						component={StatisticsCreate}
					/>

					<AuthRoute
						path="/aboutPage/statistics/index"
						component={StatisticsIndex}
					/>
					<AuthRoute
						path="/aboutPage/statistics/show/:statisticId"
						component={StatisticsShow}
					/>

					<AuthRoute
						path="/aboutPage/advantages/create"
						component={AdvantagesCreate}
					/>
					<AuthRoute
						path="/aboutPage/advantages/index"
						component={AdvantagesIndex}
					/>
					<AuthRoute
						path="/aboutPage/advantages/show/:advantageId"
						component={AdvantagesShow}
					/>

					<AuthRoute
						path="/aboutPage/questions/create"
						component={QuestionsCreate}
					/>
					<AuthRoute
						path="/aboutPage/questions/index"
						component={QuestionsIndex}
					/>
					<AuthRoute
						path="/aboutPage/questions/show/:questionId"
						component={QuestionsShow}
					/>

					<AuthRoute path="/siteinfo/create" component={HeaderCreate} />
					<AuthRoute path="/siteinfo/index" component={HeaderIndex} />
					<AuthRoute path="/siteinfo/show/:headerId" component={HeaderShow} />

					<AuthRoute
						path="/aboutPage/information/show"
						component={InformationShow}
					/>
					{/* ///////////////////////////////////////////////////////////////////////////////////////// */}
					<AuthRoute
						path="/automotivePage/automotivePosts/create"
						component={AutomotivePostsCreate}
					/>
					<AuthRoute
						path="/automotivePage/automotivePosts/index"
						component={AutomotivePostsIndex}
					/>
					<AuthRoute
						path="/automotivePage/automotivePosts/show/:automotivePostId"
						component={AutomotivePostsShow}
					/>
					{/* ///////////////////////////////////////////// */}
					<AuthRoute
						path="/automotivePage/automotiveVideo/create"
						component={AutomotiveVideoCreate}
					/>
					<AuthRoute
						path="/automotivePage/automotiveVideo/index"
						component={AutomotiveVideoIndex}
					/>
					<AuthRoute
						path="/automotivePage/automotiveVideo/show/:automotiveVideoId"
						component={AutomotiveVideoShow}
					/>
					{/* ///////////////////////////////////////////// */}
					<AuthRoute
						path="/automotivePage/automotiveSections/create"
						component={AutomotiveSectionsCreate}
					/>
					<AuthRoute
						path="/automotivePage/automotiveSections/index"
						component={AutomotiveSectionsIndex}
					/>
					<AuthRoute
						path="/automotivePage/automotiveSections/show/:automotiveSectionId"
						component={AutomotiveSectionsShow}
					/>

					{/* ///////////////////////////////////////////////////////////////////////////////////////// */}

					<AuthRoute
						path="/industryPage/posts/create"
						component={PostsCreate}
					/>
					<AuthRoute path="/industryPage/posts/index" component={PostsIndex} />
					<AuthRoute
						path="/industryPage/posts/show/:postId"
						component={PostsShow}
					/>

					<AuthRoute
						path="/industryPage/video/create"
						component={VideoCreate}
					/>
					<AuthRoute path="/industryPage/video/index" component={VideoIndex} />
					<AuthRoute
						path="/industryPage/video/show/:videoId"
						component={VideoShow}
					/>
					{/* /////////////////////////////////////////////////////// */}

					<AuthRoute
						path="/automotivePage/automotiveHeaders/create"
						component={AutomotiveHeadersCreate}
					/>
					<AuthRoute
						path="/automotivePage/automotiveHeaders/index"
						component={AutomotiveHeadersIndex}
					/>
					<AuthRoute
						path="/automotivePage/automotiveHeaders/show/:automotiveHeaderId"
						component={AutomotiveHeadersShow}
					/>
					{/* /////////////////////////////////////////////////////// */}

					<AuthRoute
						path="/benefits/benefitsHeader/create"
						component={BenefitsHeaderCreate}
					/>
					<AuthRoute
						path="/benefits/benefitsHeader/index"
						component={BenefitsHeaderIndex}
					/>
					<AuthRoute
						path="/benefits/benefitsHeader/show/:benefitsHeaderId"
						component={BenefitsHeaderShow}
					/>

					{/* //////////////////////////////////////////////////////////////////////// */}
					{/* //////////////////////////////////////////////////////////////////////// */}
					<AuthRoute
						path="/benefits/benefitsVideo/create"
						component={BenefitsVideoCreate}
					/>
					<AuthRoute
						path="/benefits/benefitsVideo/index"
						component={BenefitsVideoIndex}
					/>
					<AuthRoute
						path="/benefits/benefitsVideo/show/:benefitsVideoId"
						component={BenefitsVideoShow}
					/>
					{/* //////////////////////////////////////////////////////////////////////// */}
					{/* //////////////////////////////////////////////////////////////////////// */}
					<AuthRoute
						path="/automotivePage/automotiveSpliters/create"
						component={AutomotiveSplitersCreate}
					/>
					<AuthRoute
						path="/automotivePage/automotiveSpliters/index"
						component={AutomotiveSplitersIndex}
					/>
					<AuthRoute
						path="/automotivePage/automotiveSpliters/show/:automotiveSplitersId"
						component={AutomotiveSplitersShow}
					/>
					{/* //////////////////////////////////////////////////////////////////////// */}
					{/* //////////////////////////////////////////////////////////////////////// */}
					<AuthRoute path="/spliters/create" component={SplitersCreate} />
					<AuthRoute path="/spliters/index" component={SplitersIndex} />
					<AuthRoute
						path="/spliters/show/:spliterId"
						component={SplitersShow}
					/>
					{/* //////////////////////////////////////////////////////////////////////// */}
					{/* //////////////////////////////////////////////////////////////////////// */}
					<AuthRoute path="/mainVideo/create" component={MainVideoCreate} />
					<AuthRoute path="/mainVideo/index" component={MainVideoIndex} />
					<AuthRoute
						path="/mainVideo/show/:mainVideoId"
						component={MainVideoShow}
					/>
					{/*  */}
					<AuthRoute
						path="/aboutPage/sideinfo/index"
						component={SideInfoIndex}
					/>
					<AuthRoute
						path="/aboutPage/sideinfo/create"
						component={SideInfoCreate}
					/>
					<AuthRoute
						path="/aboutPage/sideinfo/show/:sideinfoID"
						component={SideInfoShow}
					/>
					{/*  */}

					{/* //////////////////////////////////////////////////////////////////////// */}
					{/* //////////////////////////////////////////////////////////////////////// */}
					{/* //////////////////////////////////////////////////////////////////////// */}
					<AuthRoute
						path="/works/worksvideos/create"
						component={WorksVideosCreate}
					/>
					<AuthRoute
						path="/works/worksvideos/index"
						component={WorksVideosIndex}
					/>
					<AuthRoute
						path="/works/worksvideos/show/:worksVideoId"
						component={WorksVideosShow}
					/>
					{/* //////////////////////////////////////////////////////////////////////// */}

					<AuthRoute
						path="/productOnePage/ProductOneHeader/create"
						component={ProductOneHeaderCreate}
					/>
					<AuthRoute
						path="/productOnePage/ProductOneHeader/index"
						component={ProductOneHeaderIndex}
					/>
					<AuthRoute
						path="/productOnePage/ProductOneHeader/show/:productOneHeaderId"
						component={ProductOneHeaderShow}
					/>
					{/* //////////////////////////////////////////////////////////////////////// */}

					<AuthRoute
						path="/productOnePage/ProductOnePosts/create"
						component={ProductOnePostsCreate}
					/>
					<AuthRoute
						path="/productOnePage/ProductOnePosts/index"
						component={ProductOnePostsIndex}
					/>
					<AuthRoute
						path="/productOnePage/ProductOnePosts/show/:productOnePostId"
						component={ProductOnePostsShow}
					/>
					{/* //////////////////////////////////////////////////////////////////////// */}

					<AuthRoute
						path="/productTwoPage/ProductTwoHeader/create"
						component={ProductTwoHeaderCreate}
					/>
					<AuthRoute
						path="/productTwoPage/ProductTwoHeader/index"
						component={ProductTwoHeaderIndex}
					/>
					<AuthRoute
						path="/productTwoPage/ProductTwoHeader/show/:productTwoHeaderId"
						component={ProductTwoHeaderShow}
					/>
					{/* //////////////////////////////////////////////////////////////////////// */}

					<AuthRoute
						path="/productTwoPage/ProductTwoPosts/create"
						component={ProductTwoPostsCreate}
					/>
					<AuthRoute
						path="/productTwoPage/ProductTwoPosts/index"
						component={ProductTwoPostsIndex}
					/>
					<AuthRoute
						path="/productTwoPage/ProductTwoPosts/show/:productTwoPostId"
						component={ProductTwoPostsShow}
					/>
					{/* //////////////////////////////////////////////////////////////////////// */}

					<AuthRoute
						path="/productThreePage/ProductThreeHeader/create"
						component={ProductThreeHeaderCreate}
					/>
					<AuthRoute
						path="/productThreePage/ProductThreeHeader/index"
						component={ProductThreeHeaderIndex}
					/>
					<AuthRoute
						path="/productThreePage/ProductThreeHeader/show/:productThreeHeaderId"
						component={ProductThreeHeaderShow}
					/>
					{/* //////////////////////////////////////////////////////////////////////// */}

					<AuthRoute
						path="/productThreePage/ProductThreePosts/create"
						component={ProductThreePostsCreate}
					/>
					<AuthRoute
						path="/productThreePage/ProductThreePosts/index"
						component={ProductThreePostsIndex}
					/>
					<AuthRoute
						path="/productThreePage/ProductThreePosts/show/:productThreePostId"
						component={ProductThreePostsShow}
					/>
				</Layout>
			</Switch>
		</Router>
	);
}

export default App;

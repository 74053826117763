import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Loader from "../../../components/shared/Loader";
import { updateMeta } from "../../../store/actions/metaAction";

import Views from "./siteInfoComponents";
const HeaderShow = ({ match }) => {
	const {
		params: { headerId },
	} = match;

	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const Component = useMemo(() => Views[headerId], [headerId]);

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "تعديل تفاصيل معلومة عن الموقع",
				breadcrumb: "معلومة عن الموقع",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	return (
		<section>
			{loading && <Loader />}{" "}
			<Component key={headerId} ID={headerId} loadersetter={setLoading} />
		</section>
	);
};

export default HeaderShow;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../../components/shared/Loader";
import { updateMeta } from "../../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const TagCreate = () => {
	const history = useHistory();
	const [name, setName] = useState({});
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة تصنيف جديد",
				breadcrumb: "المدونة",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
		if (name?.ar?.length > 0) {
			setLoading(false);
			axios
				.post(
					`${process.env.REACT_APP_API_URL}/tags`,
					{ name: name.ar ?? "" },
					{
						headers: {
							Authorization: `Bearer ${cookie.get("token")}`,
							Accept: "application/json",
						},
					}
				)
				.then(function (response) {
					history.push("/tag/index");
					toast.success("تم اضافة الكلمة الدلية بنجاح!");
					setLoading(false);
				})
				.catch(function (error) {
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
		if (name?.en?.length > 0) {
			setLoading(false);
			axios
				.post(
					`${process.env.REACT_APP_API_URL}/tags`,
					{ name: name.en ?? "" },
					{
						headers: {
							Authorization: `Bearer ${cookie.get("token")}`,
							Accept: "application/json",
						},
					}
				)
				.then(function (response) {
					history.push("/tag/index");
					toast.success("تم اضافة الكلمة الدلية بنجاح!");
					setLoading(false);
				})
				.catch(function (error) {
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
		if (
			(name?.en?.length <= 0 && name?.ar?.length <= 0) ||
			(!name.ar && !name.en)
		) {
			toast.warn("الرجاء ادخل البيانات اولا");
			setTimeout(() => setLoading(false), 5000);
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-12">
						<div className="form-item">
							<label htmlFor="nameAr">اسم الكلمة الدلية</label>
							<input
								type="text"
								id="nameAr"
								onChange={e =>
									setName(v => {
										return { ...v, ar: e.target.value };
									})
								}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="nameEn">اسم الكلمة الدلية بالانجليزية</label>
							<input
								type="text"
								id="nameEn"
								onChange={e =>
									setName(v => {
										return { ...v, en: e.target.value };
									})
								}
							/>
						</div>
						<div className="form-item">
							<button className="bt bt-success">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default TagCreate;

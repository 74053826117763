import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";
// import { LOG_IN } from "../../store/types";

const LinksIndex = () => {
	const [links, setLinks] = useState([]);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/posts/footer_pages/all`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				console.log(response, " this is the response");
				const data = response.data.data[0].categories[0].posts;
				setLinks(data);
				setLoading(false);

				dispatch(
					updateMeta({
						title: "جميع الروابط",
						breadcrumb: "الروابط",
						button: {
							show: data.length < 5,
							text: "اضافة رابط جديد",
							link: "/footerLinks/create",
						},
					})
				);
			})
			.catch(function (error) {
				console.log(error);
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const columns = [
		{
			name: "ID",
			selector: "id",
			sortable: true,
		},
		{
			name: "العنوان",
			selector: "title",
			sortable: true,
			cell: row => {
				return row.data.title.ar;
			},
		},
		{
			name: "",
			selector: "settings",
			cell: row => (
				<ul className="list-inline settingsList">
					<li className="list-inline-item">
						<Link
							className="bt bt-sm bt-success"
							to={`/footerLinks/show/${row.id}`}
						>
							<i className="fas fa-edit"></i>
						</Link>
					</li>
					<li className="list-inline-item">
						<button
							className="bt bt-sm bt-danger"
							onClick={() => handleConfrimDelete(row.id)}
						>
							<i className="fas fa-trash"></i>
						</button>
					</li>
				</ul>
			),
		},
	];

	const handleConfrimDelete = linkID => {
		confirmAlert({
			title: "تأكيد الحذف",
			message: "هل أنت متأكد من رغبتك في الحذف؟",
			buttons: [
				{
					label: "نعم",
					onClick: () => deleteArticle(linkID),
				},
				{
					label: "لا",
					onClick: () => console.log("No"),
				},
			],
		});
	};

	const deleteArticle = linkID => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/posts/${linkID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				onStart();
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<div className="datatable">
				<DataTableExtensions columns={columns} data={links}>
					<DataTable
						noHeader
						defaultSortField="id"
						defaultSortAsc={false}
						pagination
						highlightOnHover
						direction="rtl"
						striped
					/>
				</DataTableExtensions>
			</div>
		</section>
	);
};

export default LinksIndex;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import { updateMeta } from "../../store/actions/metaAction";
import { Col, Row, Container } from "reactstrap";

const AchivmentCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [picture, setPicture] = useState(userPlaceholder);
	const [AchivmentData, setAchivmentData] = useState({});
	const dispatch = useDispatch();

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة الانجازات جديد",
				breadcrumb: "الانجازات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	useEffect(() => {
		onStart();
	});

	const HandleSubmit = e => {
		e.preventDefault();
		if (
			!AchivmentData.title.ar ||
			!AchivmentData.title.en ||
			!AchivmentData.value
		)
			return;

		const data = {
			title: AchivmentData.title.ar,
			titleObj: AchivmentData.title,
			value: AchivmentData.value,
		};

		axios
			.post(
				`${process.env.REACT_APP_API_URL}pages/achievements/sections/`,
				{
					section: data,
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				history.push("/achivment/index");
				toast.success("تم اضافة الانجاز بنجاح!");
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
	};

	return (
		<section>
			{loading && <Loader />}
			<div style={{ width: "100%" }}>
				<Container>
					<Row>
						<Col>
							<Container
								style={{
									display: "flex",
									justifyContent: "flex-start",
									alignItems: "flex-start",
									flexDirection: "column",
									gap: "2rem",
								}}
							>
								<Row style={{ width: "100%" }}>
									<Col>
										<div className="form-item">
											<input
												type="text"
												name=""
												id="AchivmentNameAR"
												placeholder="ادخل اسم الانجاز"
												onChange={e =>
													setAchivmentData({
														...AchivmentData,
														title: {
															...AchivmentData.title,
															ar: e.target.value,
														},
													})
												}
											/>
										</div>
									</Col>
								</Row>
								<Row style={{ width: "100%" }}>
									<Col>
										<div className="form-item">
											<input
												type="text"
												name=""
												id="AchivmentNameEn"
												placeholder="ادخل اسم الانجاز بالانجليزية"
												onChange={e =>
													setAchivmentData({
														...AchivmentData,
														title: {
															...AchivmentData.title,
															en: e.target.value,
														},
													})
												}
											/>
										</div>
									</Col>
								</Row>
								<Row style={{ width: "100%" }}>
									<Col>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-start",
												alignItems: "flex-start",
												flexDirection: "column",
											}}
										>
											<div className="form-item" style={{ width: "100%" }}>
												<input
													type="search"
													name="Count"
													id="otherInfo"
													onChange={e =>
														e.target.value.length < 10 && Number(e.target.value)
															? setAchivmentData({
																	...AchivmentData,
																	value: e.target.value,
															  })
															: e.target.value === ""
															? e.target.value
															: (e.target.value =
																	"الرجاء ادخال البيانات بشكل صحيح")
													}
													placeholder="الرجاء ادخل معلومة رقمية عن الانجاز"
													style={{ width: "100%" }}
												/>
												<label htmlFor="otherInfo" style={{ fontSize: "12px" }}>
													{" "}
													<span style={{ color: "red" }}>*</span> المعلومة
													الرقمية عن الانجاز
												</label>
											</div>
										</div>
									</Col>
								</Row>
							</Container>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="form-item mt-3" onClick={HandleSubmit}>
								<button className="bt bt-success btn-block">ارسال</button>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</section>
	);
};

export default AchivmentCreate;
